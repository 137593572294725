import { z } from "zod";

const optimizerStatuses = ["pending", "completed"] as const;
export const OptimizerStatus = z.enum(optimizerStatuses);
export type OptimizerStatus = z.infer<typeof OptimizerStatus>;

export const OptimizerJobSchema = z.object({
  userId: z.string(),
  user: z.string().optional(),
  startTime: z.string(),
  jobId: z.string(),
  inputUrl: z.string(),
  status: OptimizerStatus,
  outputUrl: z.string().optional(),
});
export type OptimizerJobSchema = z.infer<typeof OptimizerJobSchema>;

export const OptimizerMeta = z.object({
  userId: z.string(),
  timestamp: z.number(),
});
export type OptimizerMeta = z.infer<typeof OptimizerMeta>;

export const OptimizerOutputSchema = z.object({
  outputPath: z.string(),
  jobId: z.string(),
  internalJobId: z.string(),
  jobType: z.string(),
  status: z.string(),
});
export type OptimizerOutputSchema = z.infer<typeof OptimizerOutputSchema>;

export const OptimizerResult = z
  .object({
    message: z.string().optional(),
    solution: z
      .object({
        assignments: z.array(
          z.object({
            studentSetGroupId: z.number(),
            students: z.array(z.number()),
          })
        ),
      })
      .passthrough(),
  })
  .passthrough();

export const OptimizerResponse = z.object({
  build: z.string(),
  dataVersion: z.number(),
  exit_status: z.string(),
  id: z.string(),
  meta: OptimizerMeta,
  result: OptimizerResult,
});
export type OptimizerResponse = z.infer<typeof OptimizerResponse>;
